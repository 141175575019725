.non_loader_content,
.page_wrapper {
  min-height: auto !important;
}

.text_wrapper {
  max-width: 640px;
}

.paragraph_div {
  margin-bottom: 20px;
}

.paragraph_p {
  margin-top: 0px;
  margin-bottom: 0px;
}

.paragraph_title {
  font-size: 27px !important;
  text-align: center;
  margin: 30px 0 0px;
}

.figure_div:first-child {
  margin: 0 10px 0 0;
}

.figure_div:last-child {
  margin: 0 0 0 10px;
}
