.findings_header,
.findings_header_notfound {
  background: #edede3;
  padding: 30px 80px 10px;
  font-family: var(--oe-sans);
  margin-bottom: 60px !important;
  position: relative;
  width: 90%;
  margin: 0 auto;
}

.findings_header_notfound {
  width: 100%;
  padding: 10px 40px;
}

@media (max-width: 800px) {
  .findings_header {
    padding: 15px 40px;
    width: 100%;
  }
}

@media (max-width: 500px) {
  .findings_header {
    padding: 10px 30px;
  }
}

.description {
  /*font-style: italic;*/
  margin: 30px 0 20px;
  font-family: var(--oe-serif);
  line-height: 1.3em;
}

.description_warning {
  /*font-style: italic;*/
  margin: 30px 0 20px;
  font-family: var(--oe-serif);
  line-height: 1.3em;
  font-size: 14px;
}

.endpoint_title {
  margin: 10px auto 10px;
  width: 80%;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
}

.sep {
  margin: 0 auto;
  border-top: 2px solid #000000;
  max-width: 300px;
}

.time_frame {
  margin: 3px auto 0px;
  text-align: center;
}

.ae_graphic {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  padding: 0px;
}

.ae_graphic {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  padding: 0px;
}

.ae_graphic_mobile {
  display: none;
}

@media all and (max-width: 480px) {
  .ae_graphic {
    display: none;
  }

  .ae_graphic_mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    padding: 0px;
  }
}

.caption {
  font-style: italic;
  margin: 30px 0 50px;
  font-size: 16px;
  font-family: var(--oe-serif);
  line-height: 1.3em;
}

.citations_header {
  font-size: 18px;
  color: #d09b57;
  text-align: center;
  font-weight: bold;
  margin: 10px 0 0;
}

.findings_citations {
  font-size: 12px;
  margin: 6px -40px 0px;
}

.citation {
  display: flex;
  line-height: 1.2em;
  margin-bottom: 7px;
}

.citation_index {
  margin-right: 5px;
}
