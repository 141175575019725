.table {
  margin-top: 30px;
  border-collapse: collapse;
  border-bottom: 1px solid black;
  width: 100%;
  font-size: 14pt;
  table-layout: fixed;
  width: 100%;
}

.studyInfo {
  border-bottom: 2px solid black;
  font-size: 16pt;
}

.studyDateJournal {
  font-weight: 100;
  text-transform: capitalize;
}

.studyTitle {
  margin-top: 0.25em;
  font-weight: 100;
}

.studyAuthors {
  margin-top: 0.25em;
  font-weight: 100;
}

a.table-link:link {
  text-decoration: none;
}

.studyHeadings {
  border-bottom: 2px solid black;
}

.studyInfo > th {
  padding: 8px 0;
}

.studyHeadings > th,
.table tbody td {
  padding: 8px 8px;
}

.colIntervention {
  width: 20%;
}

.colPopulation {
  width: 20%;
}

.colComparedTo {
  width: 20%;
}

.colSize {
  width: 10%;
}

.colResult {
  width: 30%;
}

@media (pointer: none), (pointer: coarse) {
  .studyInfo {
    font-size: 14pt;
  }

  .table {
    font-size: 12pt;
  }
}
