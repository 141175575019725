.page_wrapper {
  min-height: 100vh;
  font-family: var(--oe-serif);
}

.tooltip {
  opacity: 1 !important;
  font-family: var(--oe-sans);
  font-size: 15px !important;
}

svg.section-title-icon {
  max-width: 100%;
}

.defn_paragraph_div {
  /*max-height: 0px;*/
  overflow: hidden;
  transition: max-height 0.4s ease-in-out;
}

.hidden {
  display: none;
}

.non_loader_hidden {
  display: none;
}

.non_loader_content {
  margin: 0;
  min-height: 100vh;
}

@media (max-width: 800px) {
  .non_loader_content {
    display: block;
  }
}

.total_search_studies {
  margin-top: 85px;
  font-size: 15px;
  color: grey;
}

.full_article_wrapper {
  margin: 80px 0 20px;
}

.full_article_wrapper_citeview {
  padding-bottom: 40px;
  position: relative;
}

.article_header {
  text-align: center;
  font-size: 18px;
  max-width: 640px;
  margin: 20px auto 0;
  break-after: avoid;
}

.science_writer_image {
  display: block;
  width: 100%;
  border-radius: 8px;
  margin-top: 12px;
}

@media (max-width: 800px) {
  .article_header {
    margin-top: 5px;
  }
}

.save_button_wrapper {
  text-align: left;
}

.includeexclude_button {
  display: inline-block;
  padding: 10px 14px;
  filter: brightness(100%);
  flex-shrink: 0;
  font-family: inherit;
  font-size: 23px;
  font-weight: bold;
  text-shadow: rgb(0 0 0 / 5%) 0px 1px 2px;
  color: white;
  border: none;
  box-shadow: rgb(0 0 0 / 5%) 0px 0px 0px 1px inset,
    rgb(59 69 79 / 5%) 0px 1px 0px;
  cursor: pointer;
  border-radius: 4px;

  width: 110px;
  text-align: center;
  margin: 0 10px;
}

.include_button {
  background: #9bc9a6;
}

.exclude_button {
  background: #c99b9b;
}

.hide_span_button {
  font-size: 18px;
  font-weight: bold;
  /* float: right; */
  text-align: center;
  cursor: pointer;
  color: #fff;
  /* border: 1px solid #AEAEAE; */
  border-radius: 5px;
  display: inline-block;
  line-height: 0px;
  width: 16px;
  padding: 7px 0px 7px 0px;
}

.currently_hidden {
  background: #9bc9a6;
}

.currently_showing {
  background: #c99b9b;
}

.save_button {
  display: inline-block;
  padding: 10px 14px;
  background: #0476d9;
  filter: brightness(100%);

  flex-shrink: 0;
  font-family: inherit;
  font-size: 23px;
  font-weight: bold;
  text-shadow: rgb(0 0 0 / 5%) 0px 1px 2px;
  color: white;
  border: none;
  box-shadow: rgb(0 0 0 / 5%) 0px 0px 0px 1px inset,
    rgb(59 69 79 / 5%) 0px 1px 0px;
  cursor: pointer;
  border-radius: 4px;
}

.title {
  display: block;
  position: relative;
  font-size: 36px;
  padding: 24px 0 8px;
  font-weight: 450;
  line-height: 1.2em;
  /* font-family: var(--oe-sans); */
  text-align: left;
}

.subtitle {
  display: inline-block;
  font-size: 22px;
  margin-bottom: 20px;
  text-align: left;
  width: 100%;
  font-family: var(--oe-sans);
  color: rgb(81, 81, 81);
}

.date_written {
  padding-top: 0px;
  padding-right: 22px;
  font-size: 16px;
  display: block;
  text-transform: uppercase;
  text-align: right;
  font-family: var(--oe-sans);
}

.under_title {
  padding: 0 1px 20px;
  font-family: var(--oe-sans);
  color: rgb(81, 81, 81);
}

.byline {
  font-size: 16px;
  line-height: 1.4em;
  text-align: left;
}

.what_is_this_page {
  text-align: right;
  padding: 20px 0 20px 25px;
  width: 70%;
  margin: auto 0 auto auto;
}

.audited_by {
  font-family: var(--oe-sans);
  padding-top: 1px;
  margin-bottom: 3px;
  color: rgb(67, 67, 67);
}

.audited_by b {
  font-weight: 600;
  color: rgb(24, 24, 24);
}

.author_line {
  text-align: left;

  font-size: 14px;
  background-color: rgb(238, 238, 238);
  width: 100%;
  border-radius: 8px;
  padding: 20px;
  margin-top: 12px;
}

.box_header_wrapper {
  padding: 15px 0 0;
}

@media (max-width: 800px) {
  .title {
    font-size: 24px;
    padding-top: 16px;
  }

  .subtitle {
    font-size: 18px;
    display: inline-block;
    margin-bottom: 12px;
  }

  .author_line {
    padding: 12px;
  }
}

.image_wrapper {
  width: 100%;
  padding: 10px;
}

.image_wrapper img {
  width: 100%;
}

.subt {
  font-weight: 500;
}

.table_of_contents_wrapper {
  /*flex-basis: 200px;
	flex-grow: 0;
	flex-shrink: 0;*/
  position: fixed;
  left: 0;
  width: calc((100vw - 1160px) / 2);
  top: calc(50% + 40px);
  transform: translate(0, -50%);
  /*height: 100vh;*/
}

/* Should change this to have a different nav, small and at the top */
@media all and (max-width: 1500px) {
  .table_of_contents_wrapper {
    display: none;
  }
}

.table_of_contents_fixed_width_wrapper {
  max-width: 270px;
  margin: 0 10px 0 auto;
}

.table_of_contents {
  /*width: 375px;*/
  text-align: right;
  padding: 15px 20px 15px 0;
  /*margin: 50px 40px 0;
  padding: 25px;
  border: solid 0.5px lightgrey;
  border-radius: 3px;*/
  font-family: var(--oe-sans);
  border-right: 1px solid #333333;
}

.table_of_contents.hidden {
  display: none;
}

.table_of_contents_title {
  font-size: 21px;
  margin-bottom: 15px;
  font-weight: bold;
  color: #c6704c;
  text-transform: uppercase;
}

.table_of_contents li {
  padding-top: 5px;
  padding-bottom: 5px;
  list-style-type: none;
  transition: color 0.5s ease-out;
}

.table_of_contents li:hover {
  cursor: pointer;
  color: grey;
}

.toc_link_section {
  font-size: 16px;
}

.toc_link_paragraph {
  margin-left: 20px;
  font-size: 15px;
}

.text_modal_wrapper {
  break-before: avoid;
}

.section {
  margin-bottom: 10px;
  width: 100%;
}

.section > div:first-child {
  break-after: avoid;
}

.section_body {
  break-before: avoid;
}

.title_intervention_component,
.title_condition_component {
  cursor: pointer;
  display: inline-block;
  border: 1px solid #e6d8c4;
  padding: 2px 5px;
}

.title_intervention_component:after,
.title_condition_component:after {
  content: ' ▼ ';
  font-size: 17px;
  color: #e6d8c4;
}

.paragraph_title,
.section_title {
  font-weight: 560 !important;
  letter-spacing: -0.25px;
  margin: 30px 0px 20px 0px;
  font-family: var(--oe-sans);
  text-transform: titlecase;
  break-after: avoid;
  break-inside: avoid;
}

.section_title {
  border-left: 4px solid #ef6b33;
  border-left: 4px solid;
  margin-top: 20px;
  font-size: 25px;
  line-height: 1.25;
  color: #222;
  padding-left: 16px;
  margin-left: -20px;
  border-image: linear-gradient(
      to bottom,
      rgba(239, 107, 51, 0) 0px,
      rgba(239, 107, 51, 0) 7px,
      #ef6b33 7.01px,
      #ef6b33 calc(100% - 7.01px),
      rgba(239, 107, 51, 0) calc(100% - 7px),
      rgba(239, 107, 51, 0) 100%
    )
    1;
}

@media (max-width: 800px) {
  .section_title {
    font-size: 20px;
    color: #222;
    padding-left: 12px;
    margin-left: 0px;
    border-image: linear-gradient(
        to bottom,
        rgba(239, 107, 51, 0) 0px,
        rgba(239, 107, 51, 0) 4px,
        #ef6b33 4.01px,
        #ef6b33 calc(100% - 4.01px),
        rgba(239, 107, 51, 0) calc(100% - 4px),
        rgba(239, 107, 51, 0) 100%
      )
      1;
  }
}

.paragraph_title {
  font-size: 20px !important;
  text-align: left;
  text-transform: none;
}

.figure_paragraph_div {
  margin: 40px 0 50px;
}

.figure_paragraph_inner_div {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
}

.figure_caption_p {
  margin: 0;
  text-align: center;
  font-family: var(--oe-sans);

  font-weight: 400;
  font-size: 20px;
}

.figure_div {
  margin: 0;
}

@media (max-width: 600px) {
  .figure_paragraph_div {
    margin: 40px 0 50px;
  }

  .figure_paragraph_inner_div {
    display: inherit;
  }

  .figure_div {
    width: 100% !important;
    margin: 20px 0;
  }
}

.section:first-of-type .figure_paragraph_div:first-of-type {
  margin-top: 5px;
}

.paragraph_p {
  break-before: avoid;
}

/*.section_ic:nth-child(2) .paragraph_div:nth-child(2):first-letter,
.section_int:nth-child(1) .paragraph_div:nth-child(1):first-letter {
  float: left;
  font-size: 66px;
  line-height: 0.65;
  margin-top: 12px;
  margin-right: 2px;
}
.section_ic:nth-child(2) .paragraph_div:nth-child(2):first-line,
.section_int:nth-child(1) .paragraph_div:nth-child(1):first-line {
  font-variant: small-caps;
  font-size: 22px;
  letter-spacing: 0.04em;
}*/

.text_wrapper,
.article_quote_style {
  font-size: 16px;
  line-height: 24px;
  font-weight: 450;
}

.text_wrapper span,
.grey_color,
.article_quote_style {
  color: #424242;
}

.article_quote_style {
  font-family: var(--oe-sans);
}

.article_quote_style ul {
  padding-left: 0.75em;
}

.article_quote_style > ul {
  padding-left: 0;
}

.article_quote_style :not(li, ul, ol) {
  white-space: pre-wrap;
}

.article_quote_style h1,
.article_quote_style h2,
.article_quote_style h3,
.article_quote_style h4,
.article_quote_style h5,
.article_quote_style h6 {
  font-weight: 750;
  font-size: 17px;
}

.text_wrapper {
  display: block;
  text-align: left;
  margin: 0 auto;
}

.concatenated_article {
  margin-bottom: 40px;
}

.underlined_text {
  text-decoration: underline;
}

.grey_text {
  background-color: #dbedf0f1;
}

.bold_text {
  font-weight: bold;
  font-style: normal;
}

.greyedout_text {
  background-color: #e6e6e6f1;
  text-decoration: line-through;
}

.blue_text,
.selected {
  background-color: #008eba;
  color: white;
  font-weight: 500;
}

.regular_text,
.grey_text,
.greyedout_text,
.blue_text,
.selected {
  display: inline;
  width: fit-content;
}

.grey_text:hover,
.blue_text:hover {
  cursor: pointer;
}

.references_container {
  padding: 15px 0;
}

.reference_title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 15px;
  break-after: avoid;
}

.references {
  display: grid;
  grid-template: auto / 1.5em auto;
  font-size: 15px;
  break-before: avoid;
}

.reference_idx {
  text-align: right;
  margin-right: 5px;
}

.citations,
.citations_disabled {
  font-family: var(--oe-sans);
  font-weight: 600;
  color: #0460d9;
  vertical-align: super;
  font-size: 12px;
  line-height: 14px;
}

.citations_disabled {
  color: inherit;
  font-weight: 500;
}

.citations:hover {
  cursor: pointer;
}

.citations .disagree {
  color: red;
}

.link_button {
  font-family: var(--oe-sans);
  vertical-align: super;
  line-height: 1.5em;
  padding-left: 0;
  min-height: 0;
  min-width: 0;
  max-width: 250px;
  overflow-x: hidden;
}

.button {
  cursor: pointer;
}

.link_font {
  font-family: var(--oe-sans);
  font-size: 18px;
  line-height: 1.5em;
}

.overflow_ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.grey_icon {
  color: #2e2e2e;
  font-size: 18px !important;
}

@media (max-width: 800px) {
  .grey_icon {
    font-size: 16px !important;
  }

  .link_font {
    font-size: 16px;
  }
}

.block_quote {
  display: block;
  padding: 4px 0 0 12px;
  margin: 8px;
  border-left: solid #bde6edf1;
}

/* -------Modal--------- */
.modal_content {
  margin-bottom: 15px;
  font-style: italic;
}

.modal_title {
  font-size: 22px;
}

.trial_abstract_section:hover,
.trial_snippet:hover {
  cursor: ns-resize;
}

.trial_abstract_section,
.trial_snippet {
  font-size: 16px;
  line-height: 1.3em;
}

.trial_abstract_section.hidden,
.trial_snippet.hidden {
  display: none;
}

.trial_journal,
.trial_date,
.trial_update {
  font-size: 15px;
}

.trial_journal {
  text-transform: capitalize;
  margin: 15px 0 5px 0;
  text-align: right;
}

.trial_journal span {
  font-style: italic;
}

.trial_citation {
  font-size: 13px;
  line-height: normal;
  margin-top: 30px;
}

.trial_title {
  font-weight: bold;
  font-size: 18px;
  margin: 0 0 20px 0;
}

.trial_link {
  text-decoration: none;
  color: inherit;
  transition: color 0.25s ease-in-out;
}

.trial_link:hover {
  color: #008eba;
}

.abstract_prompt {
  font-weight: bold;
}

.article_quote_style mark {
  background-color: rgba(219, 237, 240, 0.95);
  color: inherit;
  font-weight: bold;
}

@media all and (max-width: 970px) {
  .total_search_studies {
    margin-top: 110px;
  }

  .date_written {
    text-align: center;
  }

  .full_article_wrapper,
  .full_article_wrapper_citeview {
    padding-bottom: 10px;
  }

  .section_body {
    margin-left: 0;
  }

  .paragraph_p {
    margin-left: 0;
  }
}

@media all and (max-width: 555px) {
  .total_search_studies {
    padding: 0 10px;
  }

  .grey_icon {
    font-size: 12px !important;
  }

  .link_button {
    max-width: 150px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .section_title {
    font-size: 20px;
  }

  .table_of_contents {
    width: 90%;
    margin: 0 25px;
  }

  .text_wrapper {
    line-height: 1.4em;
  }
}
