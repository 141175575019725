.reference a {
  color: inherit;
}
.reference p {
  color: #424242;
  font-family: var(--oe-sans);
  margin: 0;
}
.reference p:not(:last-child) {
  margin-bottom: 0.25em;
}
.rating_chips {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 8px 0;
}
.rating_chip {
  border: 1px solid #2e7d32;
  border-radius: 1em;
  color: #2e7d32;
  font-size: 14px;
  padding: 2px 8px;
  text-transform: capitalize;
}
.title,
.citation_number {
  font-size: 15px;
}
.title {
  margin-bottom: 5px;
  /*min-height: 25px;*/
}
.small_text {
  font-size: 14px;
}
.gray_text {
  color: #666 !important;
}
