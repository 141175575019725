.trialTags {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
}

.trialTags div {
  padding: 5px;
  border-radius: 3px;
}

.tagHighlyCited {
  background-color: lavender;
}

.tagLowQualityJournal {
  background-color: rgb(255, 196, 196);
}

.tagMidTierJournal {
  background-color: bisque;
}

.tagTopJournal {
  background-color: rgb(203, 255, 196);
}
