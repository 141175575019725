@font-face {
  font-family: 'GoldmanBold';
  src: local('GoldmanBold'), url('./assets/quadraat.ttf') format('truetype');
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

@font-face {
  font-family: var(--oe-serif);
  src: local('9046fffd1dd573f4a0aed6ebdc01ea0d');
  /* IE9*/
  src: url('./font/9046fffd1dd573f4a0aed6ebdc01ea0d.eot');
  src:
    /* IE6-IE8 */ url('./font/9046fffd1dd573f4a0aed6ebdc01ea0d.eot?#iefix')
      format('embedded-opentype'),
    /* chrome firefox */ url('./font/9046fffd1dd573f4a0aed6ebdc01ea0d.woff2')
      format('woff2'),
    /* chrome firefox */ url('./font/9046fffd1dd573f4a0aed6ebdc01ea0d.woff')
      format('woff'),
    /* chrome firefox opera Safari, Android, iOS 4.2+*/
      url('./font/9046fffd1dd573f4a0aed6ebdc01ea0d.ttf') format('truetype'),
    /* iOS 4.1- */
      url('./font/9046fffd1dd573f4a0aed6ebdc01ea0d.svg#FF Quadraat Pro')
      format('svg');
}

@font-face {
  font-family: 'Gulliver';
  src: url('./font/poc/Gulliver-Regular.otf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Nexus Sans';
  src: url('./font/poc/NexusSansWebPro.woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Nexus Sans';
  src: url('./font/poc/NexusSansWebPro-Italic.woff');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Nexus Sans';
  src: url('./font/poc/NexusSansWebPro-Bold.woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Nexus Sans';
  src: url('./font/poc/NexusSansWebPro-BoldItalic.woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Schibsted Grotesk';
  src: url('./font/SchibstedGrotesk-VariableFont_wght.ttf');
  font-weight: 125 950;
  font-stretch: 75% 125%;
  font-style: oblique 0deg 20deg;
}

@font-face {
  font-family: 'Schibsted Grotesk';
  src: url('./font/SchibstedGrotesk-Italic-VariableFont_wght.ttf');
  font-weight: 125 950;
  font-stretch: 75% 125%;
  font-style: italic;
}

@font-face {
  font-family: 'Lora';
  src: url('./font/Lora-VariableFont_wght.ttf');
  font-weight: 125 950;
  font-stretch: 75% 125%;
  font-style: oblique 0deg 20deg;
}

@font-face {
  font-family: 'Lora';
  src: url('./font/Lora-Italic-VariableFont_wght.ttf');
  font-weight: 125 950;
  font-stretch: 75% 125%;
  font-style: italic;
}

:root {
  --oe-sans: Nexus Sans, Schibsted Grotesk, Helvetica, Arial, sans-serif;
  --oe-serif: Gulliver, Lora, Georgia, serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f8f8f8;
  /*background: #E7EEF0;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Puts the feedback button lower on the page
*/
div[class$='Middle__right'] {
  top: 90% !important;
  transform: translateY(-100%);
}

.MuiTabs-flexContainer {
  justify-content: center !important;
}

@keyframes cursor-blink {
  50% {
    opacity: 0;
  }
}

.MuiAccordion-root {
  background-color: transparent;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  box-shadow: none;
}

.MuiButton-text {
  color: #353535;
}

.MuiButton-contained {
  color: #fff;
  box-shadow: none;
  border-radius: 6px;
}

.MuiAppBar-root {
  box-shadow: none;
  border-bottom: 1px solid rgba(128, 128, 128, 0.088);
  /* height: 60px; */
  z-index: 1201;
}

.MuiToolbar-root {
  height: auto;
}

.MuiDrawer-paper {
  box-shadow: none;
}

/* .MuiModal-root {
  border-radius: 20px;
  width: 700px;
  margin-top: clamp(20px, 4vh, 500px);
  margin-bottom: clamp(20px, 4vh, 500px);
}

@media (max-width: 600px) {
  .MuiModal-root {
    width: 100%;
    margin-top: 80px;
    margin-bottom: 0px;
    border-radius: 20px 20px 0px 0px;
  }
} */

.ModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.335);
  z-index: 99999;
}

.ModalContent {
  background-color: white;
  box-shadow: rgba(20, 20, 20, 0.15) 0px 10px 38px -10px,
    rgba(20, 20, 20, 0.05) 0px 10px 20px -15px;
  border-radius: 20px;
  width: 700px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100000;
  max-height: calc(92svh);
  min-height: 400px;
  overflow: hidden;
}

@media (max-width: 600px) {
  .ModalContent {
    top: 40px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: calc(100vh - 40px);
    max-height: none;
    width: 100%;
    border-radius: 20px 20px 0px 0px;
  }
}

.ModalOverlay[data-state='open'] {
  animation: ModalOverlay-show 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
}

.ModalOverlay[data-state='closed'] {
  animation: ModalOverlay-out 125ms ease-in-out forwards;
}

.ModalContent[data-state='open'] {
  animation: ModalContent-show 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
}

.ModalContent[data-state='closed'] {
  animation: ModalContent-out 125ms ease-in-out forwards;
}

@media (max-width: 700px) {
  .ModalOverlay[data-state='open'] {
    animation: ModalOverlay-show 200ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
  }

  .ModalContent[data-state='open'] {
    animation: ModalContent-show-mobile 300ms cubic-bezier(0.16, 1, 0.3, 1)
      forwards;
  }

  .ModalContent[data-state='closed'] {
    animation: ModalContent-out-mobile 250ms ease-in forwards;
  }
}

@keyframes ModalOverlay-show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes ModalOverlay-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes ModalContent-show {
  0% {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes ModalContent-out {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
}

@keyframes ModalContent-show-mobile {
  0% {
    opacity: 0;
    transform: translate(0%, 100%) scale(1);
  }

  100% {
    opacity: 1;
    transform: translate(0%, 0%) scale(1);
  }
}

@keyframes ModalContent-out-mobile {
  0% {
    opacity: 1;
    transform: translate(0%, 0%) scale(1);
  }

  100% {
    opacity: 0;
    transform: translate(0%, 100%) scale(1);
  }
}
