.container {
  background: #edede3;
  margin: 20px auto;
  padding: 12px 40px 12px;
  width: 90%;
  text-align: left;
}

@media (max-width: 1044px) {
  .container {
    margin-left: 5px;
    margin-right: 5px;
    width: initial;
  }
}

@media (max-width: 800px) {
  .container {
    margin-top: 0;
    padding: 1px 10px;
  }
}

.header {
  color: #333333;
  font-size: 30px;
  font-weight: bold;
  margin: 20px 0 40px;
  font-family: var(--oe-sans);
  text-transform: uppercase;
  text-align: center;
}

.item {
  list-style-type: none;
  position: relative;
  /* It's needed for setting position to absolute in the next rule. */
  margin-bottom: 15px;
  line-height: 1.4em;
}

.item::before {
  content: '•';
  position: absolute;
  left: -0.8em;
  /* Adjust this value so that it appears where you want. */
  font-size: 2.1em;
  /* Adjust this value so that it appears what size you want. */
}

/*.container {
    border: 10px solid #d7e0ed;
    margin: 20px auto;
    padding: 10px 40px;
    width: 90%;
}

.header {
    color: #d09b57;
    font-size: 30px;
    font-weight: bold;
    margin: 20px 0 40px;
    font-  font-family: var(--oe-sans);
;
    text-transform: uppercase;
    text-align: center;
}*/
