.findings_header {
  background: #edede3;
  padding: 30px 80px 10px;
  font-family: var(--oe-sans);
  /*margin-bottom: -10px;*/
  position: relative;
  width: 90%;
  margin: 0 auto;
}

@media (max-width: 800px) {
  .findings_header {
    padding: 15px 40px;
    width: 100%;
  }
}

@media (max-width: 500px) {
  .findings_header {
    padding: 10px 30px;
  }
}

.description {
  /*font-style: italic;*/
  margin: 30px 0 20px;
  font-family: var(--oe-serif);
  line-height: 1.3em;
}

.description_warning {
  /*font-style: italic;*/
  margin: 30px 0 20px;
  font-family: var(--oe-serif);
  line-height: 1.3em;
  font-size: 14px;
}

.endpoint_title {
  margin: 10px auto 10px;
  width: 80%;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
}

.sep {
  margin: 0 auto;
  border-top: 2px solid #000000;
  max-width: 300px;
}

.time_frame {
  margin: 3px auto 0px;
  text-align: center;
}

.efficacy_pie_chart {
  margin: 40px 20px;
}

.efficacy_graphic {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.stat_sig_details {
  text-align: center;
  font-family: var(--oe-serif);
}

.citations_header {
  font-size: 18px;
  color: #c6704c;
  text-align: center;
  font-weight: bold;
  margin: 10px 0 0;
}

.findings_citations {
  font-size: 12px;
  margin: 6px -40px 0px;
}

.citation {
  display: flex;
  line-height: 1.2em;
  margin-bottom: 7px;
}

.citation_index {
  margin-right: 5px;
}
