.container {
  background: #edede3;
  border-radius: 0px;
  padding: 30px;
  margin: 25px 0 60px;
}

.title {
  color: #c6704c;
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 25px;
  font-family: var(--oe-sans);
  text-transform: uppercase;
  break-after: avoid;
}

.body {
  margin-top: 20px;
  margin-bottom: 20px;
}

.source {
  display: inline-block;
  margin-top: 20px;
}
