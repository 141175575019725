.text_wrapper,
.article_header {
  max-width: 1000px !important;
}

/*
Trying out all of this in the main module.css file. Waiting to delete
to see if that causes problems

.paragraph_title,
.section_title {
    color: #c6704c !important;
    font-size: 30px !important;
    font-weight: bold !important;
    margin: 20px 0px 40px 0px;
    font-  font-family: var(--oe-sans);
;
    text-transform: uppercase;
}

.section_title {
    margin-bottom: 50px;
}

.section_title a {
    display: none;
}

.paragraph_title {
    font-size: 27px !important;
    text-align: center;
    margin: 30px 0 0px;
}*/

/*
.figure_div:first-child {
    margin: 0 2px 0 0;
}

.figure_div:last-child {
    margin: 0 0 0 2px;
}*/
