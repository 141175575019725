.category {
  margin-top: 4em;
}

.categoryHeader {
  font-size: 28pt;
  color: rgb(50, 50, 50, 1);
}

.categoryTables {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

@media (pointer: none), (pointer: coarse) {
  .categoryHeader {
    font-size: 22pt;
  }
}
