.bibliography {
  text-align: left;
  line-height: initial;
  /*width: 120%;
    margin-left: -10%;*/
}

.bibliographyInArticleVersionHeader {
  margin-top: -40px;
}

.bibliographyResultsMessage {
  font-size: 16pt;
  text-align: left;
  margin-top: 2em;
}

@media (pointer: none), (pointer: coarse) {
  .bibliographyResultsMessage {
    text-align: center;
  }
}
