.tableOfContents {
  border: 1px solid darkgrey;
  padding: 10px;
  text-align: left;
  /* max-width: 500px; */
  background-color: #f8f9fa;
  margin-top: 1em;
  padding-right: 3em;
  width: fit-content;
}

.tableOfContents h3 {
  font-size: 16pt;
  text-align: center;
}

.tableOfContents ol {
  list-style: none;
}

.list li {
  margin-top: 0.5em;
  font-size: 16pt;
}

@media (pointer: none), (pointer: coarse) {
  .tableOfContents {
    margin: 0 auto;
    margin-top: 2em;
  }
}
