.title {
  font-size: 24px !important;
  padding-top: 5px !important;
  margin-bottom: 18px !important;
  color: #999 !important;
}

.article_header {
  margin-top: 0 !important;
}

.full_article_wrapper_citeview {
  padding-bottom: 0 !important;
}

.paragraph_p {
  margin-bottom: 20px !important;
}

.citations {
  display: none !important;
}

.references {
  margin-bottom: 10px !important;
  line-height: 1.4 !important;
}

.references_container {
  padding-top: 1px !important;
}

.concatenated_article {
  margin-bottom: 0 !important;
}

@media (max-width: 700px) {
  .title {
    font-size: 22px !important;
    margin-bottom: 8px !important;
  }

  .full_article_wrapper_citeview {
    padding-bottom: 10px !important;
  }

  .text_wrapper {
    font-size: 16px !important;
  }

  .references_container {
    padding-top: 5px !important;
  }
}
