.living_review_tag {
  display: flex;
  margin: 30px 0 0;
}

.logo_container {
  flex: 1;
  padding-right: 5px;
}

.living_review_container {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  flex: 1;
}

.living_review_indicator {
  height: 64px;
}
