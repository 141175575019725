.container {
  background: #f3f3eb;
  padding: 16px 20px 16px 2px;
  font-family: var(--oe-sans);
  text-align: center;
  /* margin-left: 20px;
  margin-right: 20px; */
  border-radius: 10px;
}

@media (max-width: 600px) {
  .container {
    /* padding: 10px 15px; */
    padding: 8px 10px;
    margin-left: 0px;
    margin-right: 0px;
    margin: 0px -8px;
  }
}

.header {
  color: #323232;
  font-size: 22px;
  font-weight: 580;
  margin: 12px 0 0px;
  /* text-align: left; */
  text-align: center;
}

@media (max-width: 600px) {
  .header {
    font-size: 18px;
    font-weight: 580;
    /* text-align: left; */
  }
}

.logoOnTheRight {
  display: flex;
  justify-content: flex-end;
}
.logoInFigure {
  width: 150px;
}

@media (max-width: 600px) {
  .logoOnTheRight {
    display: flex;
    justify-content: flex-start;
    padding-left: 8px;
  }

  .logoInFigure {
    width: 120px;
  }
}

.description {
  font-size: 14px;
  line-height: 1.15em;
  margin: 20px 5px 20px;
  font-family: var(--oe-serif);
  text-align: left;
}

.trial_efficacy_figure {
  display: inline-block;
  position: relative;
}
