.findings_header {
  background: #edede3;
  padding: 30px 80px 10px;
  font-family: var(--oe-sans);
  /*margin-bottom: -10px;*/
  position: relative;
  width: 100%;
  margin: 25px auto 70px;
}

@media (max-width: 800px) {
  .findings_header {
    padding: 15px 40px;
    width: 100%;
  }
}

@media (max-width: 500px) {
  .findings_header {
    padding: 10px 30px;
  }
}

.description {
  /*font-style: italic;*/
  margin: 30px 0 20px;
  font-family: var(--oe-serif);
  line-height: 1.3em;
}

.endpoint_title {
  margin: 10px auto 10px;
  width: 80%;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
}

.sep {
  margin: 0 auto;
  border-top: 2px solid #000000;
  max-width: 300px;
}

.time_frame {
  margin: 3px auto 0px;
  text-align: center;
}

.marquee_graphic,
.marquee_graphic_mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  padding: 0px;
  break-inside: avoid;
}

.marquee_graphic_mobile {
  display: none;
}

@media all and (max-width: 1080px) {
  .marquee_graphic {
    display: none;
  }

  .marquee_graphic_mobile {
    display: flex;
  }
}

.caption {
  font-style: italic;
  margin: 30px 0 50px;
  font-size: 16px;
  font-weight: 100;
  font-family: var(--oe-serif);
  line-height: 1.3em;
}

.citations_header {
  font-size: 18px;
  color: #d09b57;
  text-align: center;
  font-weight: bold;
  margin: 10px 0 0;
}

.findings_citations {
  font-size: 12px;
  margin: 6px -40px 0px;
}

.citation {
  display: flex;
  line-height: 1.2em;
  margin-bottom: 7px;
}

.citation_index {
  margin-right: 5px;
}
