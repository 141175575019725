.section {
  margin-top: 35px;
}

.sectionHeader {
  font-size: 42pt;
  display: flex;
  align-items: left;
  gap: 0.5em;
}

.sectionHeader.Collapsible__is-open svg {
  transform: rotate(180deg);
  transition-duration: 500ms;
}

.sectionCategories {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding: 15px 35px;
}
